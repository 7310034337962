import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import WithdrawalTypeList from "./withdrawalType";

export default function Withdrawals() {
  const [withdrawalType, setWithdrawalType] = useState("");

  useEffect(() => {
    var currentLocation = window.location.pathname;
    var lastIndex = currentLocation.split("/");
    var withdrawType = lastIndex[lastIndex.length - 1];
    setWithdrawalType(withdrawType);
  }, [withdrawalType]);

  return (
    <div className="mainInnerView">
      <Switch>
        <Route exact path="/withdrawals/store">
          <WithdrawalTypeList type="store" />
        </Route>
        <Route exact path="/withdrawals/driver">
          <WithdrawalTypeList type="driver" />
        </Route>
      </Switch>
    </div>
  );
}
